.mainContent{
  margin: 10px;
  padding: 5px;
  text-align: center;
  background-color: #bbbbf1;
  }

  .App{
    background-color: rgb(156, 154, 151);
    /* height: 100vh; */
    min-height: 100vh;
  }


  .device-button{

    background-color: #c2e4b5;
    border: none;
    padding: 10px;
    margin: 5px;
    /* width: 35vw; */
    color: #0a0418;
  }
  
.back-button{
  background-color: #97144d;
  border: none;
  padding: 10px;
  margin: 5px;
  width: 35vw;
  color: #210474;
}
.delete-button{
  background-color: #97144d;
  border: none;
  padding: 10px;
  margin: 5px;
}


  .form-group label {
    display: block;
  }
  
  .form-group label,
  .label {
    color: #9b9b9b;
    flex-basis: auto;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 25px;
  }
  
  .radio {
    accent-color: #97144d;
  }
  
  .form-group .new-control {
    width: 95%;
    padding: 5px;
    font-size: 16px;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    color: black;
    background-color: #7d7d81;
    caret-color: #a6004d;
    border-style: none;
    border-bottom: 1px solid #adbdcc;
    line-height: 20px;
  }
  
  .form-group .new-control:focus {
    border-style: none;
    outline: none;
    border-bottom: 1px solid #adbdcc;
  }
  
  .submit {
    background-color: #2400a6;
    color: white;
    text-transform: uppercase;
    border-radius: 25px;
    padding: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    width: 60%;
    font-size: 15px;
    font-family: "Work Sans", sans-serif;
    font-weight: 600;
    margin-top: 25px;
  }
  
  .text-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .box{
    background-color: aliceblue;
    margin: 2px;
    padding-inline: 12px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.card{
  background-color: #bbbbf1;
  padding: 10px;
}

.heading{
  font-size: 20px;
  font-weight: 800;
}


.popup {
  position: fixed; /* Fixes the popup in the same position on the screen */
  top: 50%; /* 50% from the top of the screen */
  left: 50%; /* 50% from the left of the screen */
  transform: translate(-50%, -50%); /* This moves the popup up and left by 50% of its own width and height */
  background-color: #f8d7da; /* Popup background color */
  color: #721c24; /* Text color */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adding shadow for better visibility */
  z-index: 1000; /* Ensures the popup is on top of other elements */
}

.popup button {
  background-color: #155724;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer; /* Change cursor to pointer on hover */
}

.popup button:hover {
  background-color: #28a745; /* A lighter green when hovering over the button */
}

